/**
 * @Numerik
 */
import React from 'react';
import { CONTACTS } from '../../routes';
import {ButtonLinkFull} from '../utilities/forms'

const Hiro = () => {
    return (
        <section className="h-hiro-c">
            <div className="hh--img">
                <img src="/images/hiro.png" alt="" />
            </div>
            <div className="h-hiro-wrapper">
                <div className="h-hiro-caption-c">
                    
                    {/* <h1 className="h-title">Une conception <br /> unique et moderne!</h1> */}
                    <h1 className="h-title">Développez vos outils digitaux.</h1>
                    <h2 className="h-subtitle">Nous proposons à nos clients des véritables solutions modernes leur permettant d'accroître leur visibilité et d'améliorer leur image de marque sur internet.</h2>
                    <div className="h-actionner"> <ButtonLinkFull icon="fa-arrow-right" route={CONTACTS} >Nous contacter</ButtonLinkFull></div>
                   
                </div>
                
            </div>
        </section>
    );
};

export default Hiro;