/**
 * @Numerik
 */

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { SERVICES } from '../routes';

const Footer = () => {
    return (
        <div className="m-ftr--01">
            <div className="mf--wrapper">
                {/* about */}
                <div className="mf--section about">
                        <div className="mf--img-c">
                            <img src="/logo-2.svg" />
                        </div>
                        <p>
                        10gitalizer a beaucoup des plans pour l'avenir avec d'excellents clients et un développement continu de sites Web et d'applications mobile
                        </p>
                        <div className="mf--social">
                            <Link className="outlined-btn-circle" to="/"><i className="fab fa-facebook-f"></i></Link>
                            <Link className="outlined-btn-circle" to="/"><i className="fab fa-twitter"></i></Link>
                            <Link className="outlined-btn-circle" to="/"><i className="fab fa-instagram"></i></Link>
                            <Link className="outlined-btn-circle" to="/"><i className="fab fa-linkedin"></i></Link>
                        </div>
                </div>
                {/* section */}
                <div className="mf--section">
                    <h4 className="mfs-title">Entreprise</h4>
                    <div className="mfs-bdy">
                        <Link to={SERVICES} className="mfsi-link">A propos</Link>
                        {/* <Link to="/" className="mfsi-link">Porfolio</Link> */}
                    </div>
                </div>    
                {/* section */}
                <div className="mf--section">
                    <h4 className="mfs-title">Services</h4>
                    <div className="mfs-bdy">
                        <Link to={SERVICES} className="mfsi-link">SEO</Link>
                        <Link to={SERVICES} className="mfsi-link">Développement Web & Mobile</Link>
                        <Link to={SERVICES} className="mfsi-link">Web & App design</Link>
                        <Link to={SERVICES} className="mfsi-link">Marketing digitale</Link>
                        <Link to={SERVICES} className="mfsi-link">Maintenance</Link>
                        <Link to={SERVICES} className="mfsi-link">Hebergement</Link>
                    </div>
                </div>    
                {/* section */}
                <div className="mf--section">
                    <h4 className="mfs-title">Contacts</h4>
                    <div className="mfs-bdy">
                        <div className="mfs-item">
                            <span className="outlined-btn-circle"><i className="fa fa-map-marked-alt"></i></span>
                            <p>Av. adresse OUA Kintambo</p>
                        </div>
                        <div className="mfs-item">
                            <span className="outlined-btn-circle"><i className="fa fa-phone"></i></span>
                            <p><a href="tel:+243897301464"> +243 897 301 464</a></p>
                        </div>
                        <div className="mfs-item">
                            <span className="outlined-btn-circle"><i className="fa fa-envelope"></i> </span>
                            <p><a href="mailto:contact@10gitalizer.com">contact@10gitalizer.com</a> </p>
                        </div>
                    </div>
                </div>    
            </div>
            <div className="mf--legals">
                <div className="mfl--wrapper"><h5 className="caption">&copy; {(new Date().getFullYear())} 10gitalizer. Tous droits réservés </h5></div>
            </div>
            
        </div>
    );
};

export default Footer;