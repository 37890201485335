/**
 * @Numerik
 * Button
 */

 import React from 'react';

 const Button = ({type = 'submit',children,className,isLoading,disabled,onClick}) => {
     return (
         <button type={type} onClick={onClick} disabled={disabled} className={"btn "+className}>{children} {isLoading && <i className="fa fa-spinner fa-spin"></i>} </button>
     );
 };
 
 export default Button;
 