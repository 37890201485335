/**
 * @Numerik
 */
import React from 'react';
import {Avatar} from '../utilities/ui'

const Reviews = () => {

    const Review = ({name,role, message, company}) =>{
        return(
            <section className="mrv--displayer">
                <div className="mrv--wrapper">
                    <div className="mrv--avtr--c">
                        <Avatar > <i className="fa-solid fa-user rv--u-icon"></i> </Avatar>
                    </div>
                    <div className="mrv--quotes"><i class="fas fa-quote-right"></i></div>
                    <h1 className="mrv--name">{name}</h1>
                    <h2 className="mrv--function">{role}</h2>
                    <p className="mrv--review">
                        {message}
                    </p>
                    <div className="mrv--company">
                       {company}
                    </div>
                </div>
                
            </section>
        )
    }

    return (
        <div className="rw--p">
            <div className="rw--p--wrapper">
                <h3 className="subtitle">Nos commentaires</h3>
                <h1 className="rwp--title">Ce que disent les clients de <br /> <span className="our">notre agence</span> </h1>
                <div className="rvw--wrapper">
                    <Review name="M. Wane" company="Panam'as / Dakar - Sénégal" role="P.D.G" message="Aujourd'hui, nous travaillons avec des statistiques et des données plus précises afin de mieux servir nos clients." />
                    <Review name="M. Henry Seye" company="Exergie Afrique / Abidjan - C.I.V" role="P.D.G" message="10gitalizer a fait un merveilleux travail de refonte de notre site internet le faisant passer d’un outil simple et fonctionnel à un incroyable site professionnel." />
                    <Review name="M. Daouda Gassama" company="CER2E / Dakar - Sénégal" role="P.D.G" message="Les plus de votre agence : adaptabilité, service, vitesse de réalisation et conseils." />
                    <Review name="M. Cheikh Fadel Bâ" company="S.O.D.E.R / Dakar - Sénégal" role="P.D.G & CO-FONDATEUR" message="Délais tenus, très forte réactivité, sens de l'adaptation et de la compréhension, un grand sens de la compréhension des attentes du client. " />
                    <Review name="Mr. Paul Cito" company="SOKOS / Bukavu - R.D.C" role="P.D.G" message="Le professionnalisme et la réactivité de 10gitalizer sont des atouts majeurs lors de la création d’un site internet. Je vous le recommande vivement. " />
                </div>
            </div>

        </div>
    );
};

export default Reviews;