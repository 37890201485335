/**
 * @Numerik
 */

import React from 'react';

const Services = () => {

    const ServiceItem = ({icon,caption,title}) =>{
        return(
            <section className="s--l--item--01">
                <div className="s--icon-c">
                    <div className="ic"><i className={icon}></i></div>
                    <h1 className="sh--title">{title}</h1>
                </div>
                <div  className="caption">
                    <p>
                        {caption}
                    </p>
                </div>
            </section>
        );
    }

    const ServicesList = () =>{
        return(
            <div className="srvc--wrapper" id="services">
                <div className="srvc--content">
                   <h3 className="subtitle">Services</h3>
                    <h1 className="s--title">Ce que nous offrons</h1>

                    <div className="s--list--container ">
                        <div className="slc--c"> <ServiceItem title="SEO" caption="Paraîssez en premier sur google et transformez vos prospects en nouveaux clients."  icon="fa-solid fa-chart-line"    /></div>
                        <div className="slc--c"> <ServiceItem title="Développement"   icon="fa-solid fa-code" caption="Vous aussi vous avez la possibilité d’avoir un site internet adapté à vos besoins."  /></div>
                        <div className="slc--c"> <ServiceItem title="Web design" icon="fa-solid fa-compass-drafting" caption="Améliorer votre site = améliorer vos ventes."  /></div>
                        <div className="slc--c"> <ServiceItem title="Marketing digitale" caption="Nous élaborons une stratégie digitale capable de produire des résultats remarquables." icon="fa-solid fa-desktop" /></div>
                        <div className="slc--c"> <ServiceItem title="Maintenance" icon="fa-solid fa-gear" caption="Ajout ou modification de fonctionnalités, résolution des incidents & bugs, Mises à jour "  /></div>
                        <div className="slc--c"> <ServiceItem title="Hebergement"  icon="fa-solid fa-server" caption="Des hebergements rapide et sécurisés avec une assistance disponible 24h/24"  /></div>
                    </div>
                </div>
                 
            </div>
        )
    }

    const Domain = () =>{
        return(
            <div className="domain--wrapper">
                 <h3>Domain</h3>
                 <h1>Notre domaine d'expertise</h1>
            </div>
        )
    }

    return (
        <div className="srvc--p">
            <ServicesList />
            
        </div>
    );
};

export default Services;