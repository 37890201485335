/**
 * @Numerik
 */
import React from 'react';

const Partners = () => {
    const Displayer = ({alt,img,link }) =>{
        return (
            <a href={link} target={link ? "_blank" : ""} className="ptrnr-dsp--01" title={alt}>
                <img src={img} alt={alt} />
            </a>
        )
    }
    return (
        <section className="prtn--p--10">
            <div className="prtn--wrapper">
                <h1 className="prtn--title">Ils nous font confiance</h1>
                <div className="ptr-list">
                    <Displayer img="/images/partners/boxart.jpg" alt="Boxart" link="https://www.box-arts.com" />
                    <Displayer img="/images/partners/onediez.jpg" alt="OneDiez" link="https://www.onediez.com" />
                    <Displayer img="/images/partners/exergie-afrique.jpg" alt="Exergie Afrique" link-old="https://www.exergieafrique.com" />
                    <Displayer img="/images/partners/soder.jpg" alt="SODER" link="https://www.sodersarl.com" />
                    <Displayer img="/images/partners/panamas.jpg" alt="Panam'as" link="https://www.panamas.sn" />
                    <Displayer img="/images/partners/keziah-paris.jpg" alt="Keziah Paris" link="https://www.keziahparis.com" />
                    <Displayer img="/images/partners/cer2e.jpg" alt="CER2E" link-old="https://www.cer2e.com" />
                    <Displayer img="/images/partners/sokos.jpg" alt="SOKOS" link-old="https://www.sokos-service.com" />
                    <Displayer img="/images/partners/barrista.jpg" alt="Barrista" link="https://www.barrista.sn" />
                    
                </div>
            </div>
            
        </section>
    );
};

export default Partners;