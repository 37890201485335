/**
 * @Numerik
 * app router
 */
import React from 'react';
import {Routes,Route} from 'react-router-dom'
import * as app from './index';
//components
import HomeComponent from '../components/Home'
const router = () => {
    return (
        <Routes>
            <Route path={app.HOME} element={<HomeComponent />} />
        </Routes>
    );
};

export default router;