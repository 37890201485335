/**
 * @Numerik
 */
import React from 'react';

const Avatar = ({children}) => {
    return (
        <div className="avtr--01">
            {children}
        </div>
    );
};

export default Avatar;