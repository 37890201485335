/**
 * @Numerik
 */
import React from 'react';
import Hiro from './home/Hiro'
import WhyChooseUs from './home/WhyChooseUs'
import Reviews from './home/Reviews'
import Partners from './home/Partners'
import Services from './home/Services'
import Contacts from './home/Contacts'
const Home = () => {
    return (
        <div>
            <Hiro />
            <WhyChooseUs />
            <Services />
            <Reviews />
            <Contacts />
            <Partners />
        </div>
    );
};

export default Home;