/**
 * @Numerik
 */

import React,{useEffect} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { CONTACTS, HOME, SERVICES } from '../routes';
import {ButtonLink , ButtonLinkFull} from './utilities/forms'

const Header = () => {
    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);
    },[]);

    function handleScroll(){
        let element  = document.getElementById("mstr--header");
        if(window.pageYOffset > 300){
            element.classList.add('with-bg');
        }
        else{
            element.classList.remove('with-bg');
        }
    }

    return (
        <div className="m--hdr--01" id="mstr--header">
            <div className="mh--wrapper">
                {/* logo */}
                <div className="logo-c">
                    <Link to={HOME}>
                        <img alt="Logo" src="/logo-web.svg" />
                    </Link>
                </div>
                {/* menus */}
                <div className="menus-s">
                    <Link to={HOME} className="menus-item active">Acceuil</Link>
                    <Link to={SERVICES} className="menus-item">Services</Link>
                    <Link to={CONTACTS} className="menus-item">Contact</Link>
                    {/* <Link to="/" className="menus-item">Porfolio</Link> */}
                </div>
                {/* contacts */}
                <div className="contact-s">
                    <ButtonLink href="tel:+243 897 301 464" icon="fa-phone" className="primary" >+243 897 301 464</ButtonLink>
                    <ButtonLinkFull route={CONTACTS} icon="fa-arrow-right" >Nous contacter</ButtonLinkFull>
                </div>
            </div>
        </div>
    );
};

export default Header;