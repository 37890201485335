/**
 * @Numerik
 * 
 * The helper for all api communication
 */

 import axios from 'axios';
 /** Format the token */
 const getFormattedTAccessToken = token => {
     return 'Bearer ' + token;
   };
   
 
 export const makeRequest = ({
     method= 'get',
     route,
     data,
     token,
     onSuccess,
     onError,
     setIsLoading,
     keepLoadOnSuccess = false
 }) =>{
     //format Bearer token
     const fToken = getFormattedTAccessToken(token);
     //add token to the request header
     axios.defaults.headers.common.Authorization = fToken;
     //set the loading indicator
     if (setIsLoading) {
         setIsLoading(true);
     }
     //make the API Request
     // try if request is callable
     try{
         axios[method](route,data).then(response =>{
             // if request succeded
             // put response data back through  on success callback function
             if(onSuccess){
                 onSuccess(response)
             }
             //clear loading indicator  
             if (setIsLoading && !keepLoadOnSuccess) {
                 setIsLoading(false);
                 // test
                 console.log(response);
     }
         }).catch(error =>{
             
             //test
             console.log(error.response);
 
             
 
             //clear loading indicator  
             if (setIsLoading) {
                 setIsLoading(false);
             }
             //catch network error
             if(error.message === 'Network Error')
             {
                 alert("Problème de réseau");
                 return;
             }
             //if unauthenticated
             if(error.response.status === 401){
                //  notifyToast({message:"Vous devez être connecté pour effectuer cette action",color:danger})
                //  return;
             }
             // handle request error
             if(onError) {
                 onError(error.response);
             }
     
         });
     }catch(error ){
         // print the cathed error on console
         console.log(error)
     }
     
 }
 