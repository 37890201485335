/**
 * @Numerik
 * Contacts
 */
 import React,{useState} from 'react';
 import { makeRequest } from '../../helpers/request';
 import { errorToast, successToast } from '../../helpers/Toaster';
 import { setValidationErrors } from '../../helpers/validation';
 import { CONTACTS_ROUTE } from '../../routes/api';
 import {Input,Textarea,Button} from '../utilities/forms'
 import ReCAPTCHA from "react-google-recaptcha"
 
 const Contacts = () => {
     const [name,setName] = useState('');
     const [email,setEmail] = useState('');
     const [phone,setPhone] = useState('');
     const [message,setMessage] = useState('');
     const [isLoading,setIsLoading] = useState(false);
     const [errors,setErrors] = useState([]);
     const [captchaToken,setCaptchaToken] = useState(null);
 
     //on submit
     function onSubmit(e) {
         e.preventDefault();
         if(!captchaToken)
         {
             errorToast("Captcha non valide");
             return;
         }
         makeRequest({method: 'post',route:CONTACTS_ROUTE,data:{
                 nom:name,
                 email:email,
                 phone:phone,
                 message:message
             },
             onSuccess:onSuccess,
             onError:onError,
             setIsLoading:setIsLoading,
         })
     }
 
     //on success callback
     function onSuccess(response) {
         if(response.status === 201){
             successToast("Votre message a été envoyé, nous vous récontacterons dans les 24h. Merci!");
             setName('');
             setEmail('');
             setPhone('');
             setMessage('');
             setErrors([]);
         }
     }
 
     // on error callback
     function onError(error){
         setValidationErrors(setErrors,error);
     }
 
     //
     function onCaptchaChange(response){
         setCaptchaToken(response);
     }
     
 
 
     return (
         <section className="ctkt--p" id="contacts">
             <div className="ctkt--wrapper">
                 <div className="ctkt--content">
                     <div className="ctkt--img">
                         <img src="/images/bg/contact-us.png" alt="" />
                     </div>
                     <div className="form-s">
                         <div className="form-hdr">
                             <h1 className="frm--title">Vous réfléchissez à une interface digitale ?</h1>
                             <h4 className="frm--subtitle">Stratégie, conception et développement. <br/>Quel que soit le défi, nous proposons toujours une solution adaptée.</h4>
                         </div>
                         <form method="post" onSubmit={onSubmit}>
                             <Input errorMessage={errors.nom} value={name} onChange={setName} type="text" name="nom" label="Nom complet" required={true} />
                             <Input errorMessage={errors.email} value={email} onChange={setEmail} type="email" name="email" name="E-mail" label="E-mail" required={true}   />
                             <Input errorMessage={errors.phone} value={phone} onChange={setPhone} type="tel" name="phone" label="Numero de téléphone" required={true}   />
                             <Textarea errorMessage={errors.message} value={message} onChange={setMessage}  name="message" label="Message" required={true} className="textarea"   />
                             <div className="my-2">
                             <ReCAPTCHA
                                 sitekey="6LegZPIdAAAAAAv1sO5uqRfcCao-Qi4m8Dpqzubl"
                                 onChange={onCaptchaChange}
                             />
                             </div>
                             <Button className="btn btn-secondary" isLoading={isLoading} disabled={isLoading} >Envoyer le message</Button>
                         </form>
                     </div>
                 </div>
                 
             </div>
         </section>
     );
 };
 
 export default Contacts;