/**
 * @Numerik
 * 
 * API routes
 */

//  export const host = 'http://127.0.0.1:8000'
 export const host = 'https://api.10gitalizer.com'

 const base_url = host + '/api';
 
 //contact
 export const CONTACTS_ROUTE = base_url+'/contacts'
 