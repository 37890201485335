/**
 * @Numerik
 */

import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Router from './routes/router';
const App = () => {
  return (
    <div>
      <Header />
      <div id="app-content">
        <Router />
      </div>
      <Footer />
    </div>
  );
};

export default App;