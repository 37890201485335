/**
 * @Numerik
 * text input
 */

 import React from 'react';

 const TextInput = ({name,label,value,onChange,type="text",accept="*",required,errorMessage,placeholder,className,min,max})=>{
 
     const setOnChange =(e)=>{
         if(type !== 'file')
         {
             
             onChange(e.target.value);
             return;
         }
         let reader = new FileReader();
         reader.onload = ()=>{
             let url = reader.result;
             onChange(url);
         }
         reader.readAsDataURL(e.target.files[0]);
     }
         
     
 
     return(
         <div className="form-group">
             {label && <label>{label}</label>}
             <input type={type} accept={accept} name={name} className={["form-control "+className]} placeholder={placeholder}  value={value} onChange={(e) => setOnChange(e)} required={required} min={min} max={max} />
             {errorMessage && <span className="text-danger">{errorMessage}</span>}
         </div>
     )
 }
 
 export default TextInput;
 