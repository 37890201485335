/**
 * @Numerik
 */
import React from 'react';
import { CONTACTS } from '../../routes';
import { ButtonLinkFull } from '../utilities/forms';

const WhyChooseUs = () => {

    const Item = ({title,caption,icon}) =>{
        return(
            <div className="wycu--p-item">
                <span className="blck-icon--01"><i class={"fas "+icon}></i></span>
                <div>
                    <h3 className="wycu-it-title">{title}</h3>
                    <p>
                       {caption}</p>
                </div>
            </div>
        )
    }

    return (
        <section className="wycu--p">
            <div className="wycu--wrapper">
                
                <div className="wycu--caption-c">
                    <h1 className="wycu--title">Pourquoi nous choisir </h1>
                    <div className="wycu--img">
                    <img src="/images/wycu.png" alt="" />
                </div>
                    <Item title="Expérimenté" caption="Nous sommes dans le domaine depuis 10 ans et avons conçu avec succès plus de 100 sites Web et Applications mobile." icon="fa-balance-scale" />
                    <Item title="Solutions axées sur le client" caption="Notre entreprise est axée sur les demandes des clients pour un service exceptionnel qui lui est adapté et lui apporte de la valeur" icon="far fa-dot-circle" />
                    <Item title="Touchez une plus grande audience" icon="fa-globe-africa" caption="Nous aidons votre entreprise à se développer avec soit un site Web, une application mobile, le référencement ou par marketing numérique pour vous aider à toucher plus de clients"/>
                    <div className="inline-block">
                        <ButtonLinkFull href="tel:+243897301464" icon="fa-arrow-right" classData="big-button" >Appelz-nous</ButtonLinkFull>
                    </div>
                    
                </div>
                
            </div>
        </section>
    );
};

export default WhyChooseUs;
