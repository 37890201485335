/**
 * @Numerik
 */

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const ButtonLink = ({icon,children,route,className,href}) => {
    return (
        <>
            {href ? 
            <a href={href} className={"btn-link-outlined-01 "+className}>
                <i className={"fa "+icon}></i>
                {children}
            </a> 
        : <Link to={route} className={"btn-link-outlined-01 "+className}>
            <i className={"fa "+icon}></i>
            {children}
        </Link> }
        </>
        
    );
};

export default ButtonLink;