/**
 * @Numerik
 */

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const ButtonLinkFull = ({icon,route,children,href,classData}) => {
    return (
        <>
            {href ? <a href={href} className={"btn-link-full-01 "+classData}>
            {children}
            <i className={"fa "+icon}></i>
        </a> : <Link to={route} className={"btn-link-full-01 "+classData}>
            {children}
            <i className={"fa "+icon}></i>
        </Link>}
        </>
    );
};

export default ButtonLinkFull;