/**
 * @Numerik
 * validations helper
 */

//set errors

export const setValidationErrors = (setErrors,error) =>{
    if (error.data && error.data.errors) {
        setErrors(error.data.errors);
      }
}